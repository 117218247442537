import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useGlobalUI } from "../../Context/GlobalUIContext";

const ReusableDialog = () => {
  const { dialogOpen, dialogConfig, closeDialog } = useGlobalUI();

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogConfig.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogConfig.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{dialogConfig.actions}</DialogActions>
    </Dialog>
  );
};

export default ReusableDialog;
