import axios from "axios";


export async function GetRecordList(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    pageSize: 10,
    offset: 0,
    searchQuery: "",
    customerId: null,
    itemId: null,
    objectId: null,
    filterId: null,
    collectionFilter: null,
    collectionType: null,
    documentNumber: null,
    documentType: null,
  };

  const requestBody = {
    object_type: params.objectType,
    page_size: params.pageSize || defaultParams.pageSize,
    offset: params.offset || defaultParams.offset,
    query: params.searchQuery || defaultParams.searchQuery,
    customer_id: params.customerId || defaultParams.customerId,
    item_id: params.itemId || defaultParams.itemId,
    filter_id: params.filterId || defaultParams.filterId,
    collection_filter: params.collectionFilter || defaultParams.collectionFilter,
    collection_type: params.collectionType || defaultParams.collectionType,
    object_id: params.objectId || defaultParams.objectId,
    document_number: params.documentNumber || defaultParams.documentNumber,
    document_type: params.documentType || defaultParams.documentType,
  };

  try {
    const response = await axios.post(
      apiUrl + "/api/Search/GetRecordList",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in GetRecordList:", error.message);
    console.error(error.response?.data || error.request || error);
    return [];
  }
}

export async function GetSingleRecordUsingId(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    objectType: null,
    idType: null,
    objectId: null,
    rowId: null,
    filterId: null,
    customData: null,
  };

  const requestBody = {
    object_type: params.objectType || defaultParams.objectType,
    filter_id: params.filterId || defaultParams.filterId,
    object_id: params.objectId || defaultParams.objectId,
    row_id: params.rowId || defaultParams.rowId,
    custom_data: params.customData || defaultParams.customData,
  };

  if (params.idType === "id") {
    requestBody.object_id = params.objectId || defaultParams.objectId;
  } else if (params.idType === "row") {
    requestBody.row_id = params.objectId || defaultParams.objectId;
  }

  try {
    const response = await axios.post(
      apiUrl + "/api/search/GetSingleRecordUsingId",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while fetching ${params.objectType} data.`
      );
    }
  }
}

export async function UpdateRecord(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    objectType: null,
    rowId: null,
    objectUpdates: null,
    objectKeys: null,
    filterId: null,
    collectionId: null,
    position: null,
    customData: null,
  };

  const requestBody = {
    object_type: params.objectType || defaultParams.objectType,
    object_id: params.rowId ? String(params.rowId) : defaultParams.rowId,
    object_updates: params.objectUpdates || defaultParams.objectUpdates,
    object_keys: params.objectKeys || defaultParams.objectKeys,
    filter_id: params.filterId || defaultParams.filterId,
    position: params.position || defaultParams.position,
    collection_id: params.collectionId || defaultParams.collectionId,
    custom_data: params.customData || defaultParams.customData,
  };

  try {

    const response = await axios.post(
      apiUrl + "/api/update/UpdateRecord",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while updating ${requestBody.object_type} data.`
      );
    }
  }
}

export async function InsertRecord(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    objectType: null,
    objectUpdates: null,
    objectKeys: null,
    filterId: null,
    rowId: null,
  };

  const requestBody = {
    object_type: params.objectType || defaultParams.objectType,
    object_updates: params.objectUpdates || defaultParams.objectUpdates,
    object_keys: params.objectKeys || defaultParams.objectKeys,
    filter_id: params.filterId || defaultParams.filterId,
    row_id: params.rowId || defaultParams.rowId,
  };

  try {
    console.log(requestBody.object_updates);
    const response = await axios.post(
      apiUrl + "/api/insert/InsertRecord",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while inserting ${requestBody.object_type} data.`
      );
    }
  }
}

export async function DeleteRecord(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    objectType: null,
    objectKeys: null,
    rowId: null,
    fileName: null,
    customerId: null,
    filterId: null,
  };

  const requestBody = {
    object_type: params.objectType || defaultParams.objectType,
    object_keys: params.objectKeys || defaultParams.objectKeys,
    object_id: params.rowId ? String(params.rowId) : defaultParams.rowId,
    file_name: params.fileName || defaultParams.fileName,
    row_id: params.rowId || defaultParams.rowId,
    customer_id: params.customerId || defaultParams.customerId,
    filter_id: params.filterId || defaultParams.filterId,
  };

  try {
    const response = await axios.post(
      apiUrl + "/api/delete/DeleteRecord",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while deleting ${requestBody.object_type} data.`
      );
    }
  }
}

export async function DeleteImage(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    objectType: null,
    rowId: null,
    fileName: null,
  };

  const requestBody = {
    file_name: params.fileName || defaultParams.fileName,
  };

  try {
    const response = await axios.post(
      apiUrl + "/api/images/DeleteImage",
      requestBody,
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.error("Error response:", error.response.data);
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while deleting ${requestBody.object_type} data.`
      );
    }
  }
}

export async function CalcSalesPrice(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    objectType: null,
    rowId: null,
    objectUpdates: null,
    objectKeys: null,
    priceQuery: null,
  };

  const requestBody = {
    price_query: params.priceQuery || defaultParams.priceQuery,
  };

  try {
    const response = await axios.post(
      apiUrl + "/api/calcsalesprice/calcsalesprice",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while updating ${requestBody.object_type} data.`
      );
    }
  }
}

export async function ValidateResetToken(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    resetToken: null,
  };

  const requestBody = {
    reset_token: params.resetToken || defaultParams.resetToken,
  };

  try {
    const response = await axios.post(
      apiUrl + "/api/reset/validate-reset-token",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while fetching ${params.objectType} data.`
      );
    }
  }
}

export async function ResetPassword(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    resetToken: null,
    password: null,
  };

  const requestBody = {
    reset_token: params.resetToken || defaultParams.resetToken,
    password: params.password || defaultParams.password,
  };

  try {
    const response = await axios.post(
      apiUrl + "/api/reset/reset-password",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while fetching ${params.objectType} data.`
      );
    }
  }
}

export async function GetCompanyId(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    loginId: null,
  };

  const requestBody = {
    login_id: params.loginId || defaultParams.loginId,
  };

  try {
    const response = await axios.post(
      apiUrl + "/api/reset/get-company-id",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`An error occurred: ${error.response.status}`);
    } else if (error.request) {
      throw new Error("No response received from the server.");
    } else {
      throw new Error(
        `An error occurred while fetching ${params.objectType} data.`
      );
    }
  }
}

export async function GeneratePasswordReset(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const defaultParams = {
    loginId: null,
    companyId: null,
  };

  const requestBody = {
    login_id: params.loginId || defaultParams.loginId,
    company_id: params.companyId
      ? String(params.companyId)
      : defaultParams.companyId,
  };

  try {
    const response = await axios.post(
      apiUrl + "/api/reset/generate-password-reset",
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    console.log("Password reset request successful:", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export function formatDateString(dateString) {
  if (!dateString) {
    console.log("Empty date string provided");
    return "";
  }
  // First, try parsing the date directly
  let date = new Date(dateString);

  // If that didn't work, try parsing it manually
  if (isNaN(date.getTime())) {
    const parts = dateString.split(/[- :]/);
    date = new Date(
      parts[0],
      parts[1] - 1,
      parts[2],
      parts[3],
      parts[4],
      parts[5]
    );
  }

  if (isNaN(date.getTime())) {
    console.error("Invalid date string:", dateString);
    return "Invalid Date";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} @ ${hours}:${minutes}`;
  return formattedDate;
}
