import * as React from 'react';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { useAppState } from '../../reduxModule';
import SideMenu from './SideMenu';

function SideMenuDrawerMobile({ toggleDrawer }) {
  const { sideMenuOpen } = useAppState();

  return (
    <Drawer
      anchor="left"
      open={sideMenuOpen}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: "none",
          backgroundColor: "background.paper",
          width: 240,
          transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important',
        },
      }}
    >
      <SideMenu />
    </Drawer>
  );
}

export default SideMenuDrawerMobile;
