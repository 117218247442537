import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GetRecordList } from "../../utils";
import { Chip, TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PAGE_SIZE, ROW_HEIGHT } from "../../constants";
import { OrderStatusCell } from "../Lists/Helpers/ListHelper";

const DashboardOrderList = () => {
  const pageSize = 5;
  const [rows, setRows] = useState([]);
  const [metaDataList, setMetaDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const ReceivedByERPCell = (params) => (
    <Chip
      label={params.value === 1 ? "True" : "False"}
      color={params.value === 1 ? "success" : "error"}
      size="small"
    />
  );

  const OrderDateCell = (params) => params.value.substring(0, 16);

  const columns = [
    {
      field: "document_number",
      headerName: "Document Number",
      flex: 1,
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      flex: 1,
    },
    {
      field: "order_date",
      headerName: "Order Date",
      renderCell: OrderDateCell,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Order Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <OrderStatusCell
            metaDataList={metaDataList}
            value={params.row.status}
          />
        );
      },
      flex: 1,
    },
    {
      field: "received_by_ERP",
      headerName: "With Office",
      renderCell: ReceivedByERPCell,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const { page, pageSize } = paginationModel;
      const offset = page * pageSize;
      console.log(
        `Fetching data with pageSize: ${pageSize}, offset: ${offset}, searchQuery: ${searchQuery}`
      );
      const data = await GetRecordList({
        objectType: "s_order",
        pageSize: pageSize,
        offset: offset,
        query: searchQuery,
      });

      console.log(`Received ${data.length} rows from API`);

      if (data.record_list && data.record_list.length > 0) {
        setRows(data.record_list);
        setTotalRows(data.total_records);
        setMetaDataList(data.meta_data);
        console.log(`Total records: ${data.total_records}`);
      } else {
        setRows([]);
        setTotalRows(0);
      }
    } catch (err) {
      setError(err.message);
      console.error("Error fetching orders:", err);
      setRows([]);
      setTotalRows(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [paginationModel, searchQuery]);

  const handlePaginationModelChange = (newModel) => {
    console.log("Pagination model changed:", newModel);
    setPaginationModel(newModel);
  };

  const getRowId = (row) =>
    row.id || `${row.document_number}-${row.customer_id}`;

  const handleRowClick = (params) => {
    navigate(`/orders/id/${params.row.document_number}`);
  };

  return (
    <Box sx={{ width: "100%", height: "auto", minHeight: 450 }}>
      <div style={{ width: "100%" }}>
        <DataGrid
          sx={{ fontSize: 12 }}
          rows={rows}
          columns={columns}
          paginationMode="server"
          rowCount={totalRows}
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[pageSize]}
          getRowId={getRowId}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          rowHeight={ROW_HEIGHT}
          headerHeight={ROW_HEIGHT}
        />
      </div>
      {error && <Box sx={{ color: "red", mt: 2 }}>Error: {error}</Box>}
    </Box>
  );
};

export default DashboardOrderList;
