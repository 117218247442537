import React from "react";
import {
  Checkbox,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";

const PaymentMethodDisplay = ({ inputString }) => {
  console.log("PaymentMethodDisplay received inputString:", inputString);

  const PAYMENT_METHODS = ["Account", "Card", "Cash on Delivery"];

  const parseInputString = (str) => {
    if (!str || typeof str !== "string") {
      console.error("Invalid input string:", str);
      return [];
    }

    const statuses = str.split(",").map(Number);

    if (statuses.length !== 3) {
      console.error("Input string does not contain exactly 3 values:", str);
      return [];
    }

    return PAYMENT_METHODS.map((method, index) => ({
      name: method,
      enabled: statuses[index] === 1,
    }));
  };

  const paymentMethods = parseInputString(inputString);

  if (paymentMethods.length === 0) {
    return <Typography color="error">Invalid payment method data</Typography>;
  }

  return (
    <List disablePadding sx={{ mr: 1 }}>
      {paymentMethods.map((method, index) => (
        <ListItem key={index} dense disableGutters sx={{m:0, p:0}}>
          
          <ListItemText
            primary={
              <Typography
                variant="body2"
                sx={{ mr: 2 }}
                style={{ fontSize: "0.8rem" }}
              >
                {method.name}
              </Typography>
            }
          />
          <div sx={{ mr: 2 }}>
            
            <Checkbox
              edge="end"
              checked={method.enabled}
     
            />
          </div>
        </ListItem>
      ))}
    </List>
  );
};

export default PaymentMethodDisplay;
