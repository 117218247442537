import * as React from 'react';
import { styled } from '@mui/material/styles';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useAppSelector } from '../../reduxModule';

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: theme.palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: 'center',
  },
}));

const NavbarBreadcrumbs = React.memo(() => {
  const breadcrumbs = useAppSelector((state) => state.app.breadcrumbs);
  const navigate = useNavigate();

  const handleClick = React.useCallback((url, event) => {
    event.preventDefault();
    navigate(url);
  }, [navigate]);

  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      {breadcrumbs.map((crumb, index) => (
        <Link
          key={index}
          color={index === breadcrumbs.length - 1 ? 'text.primary' : 'inherit'}
          href={crumb.url}
          onClick={(event) => handleClick(crumb.url, event)}
          sx={{
            fontWeight: index === breadcrumbs.length - 1 ? 600 : 'inherit',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {crumb.title}
        </Link>
      ))}
    </StyledBreadcrumbs>
  );
});

NavbarBreadcrumbs.displayName = 'NavbarBreadcrumbs';
export default NavbarBreadcrumbs;
