import React, { useState, useEffect } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  createSubsetObject,
  formatDateString,
  GetRecordList,
} from "../../utils";
import { TextField, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowCircleRightOutlined from "@mui/icons-material/ArrowCircleRightOutlined";

import { PAGE_SIZE } from "../../constants";

import { ListBox } from "./Helpers/ListHelper";
import DetailDrawer from "../Drawers/DetailDrawer";
import { useAppState } from "../../reduxModule";
import { Delete } from "@mui/icons-material";

const CONFIG = {
  objectType: "payment_term",
  searchTitle: "Search Payment Terms",
  breadcrumbTitle: "Payment Terms",
  breadcrumbUrl: "/settings/payment-terms",
  detailTitle: "Payment Term Details",
 
};

const PaymentTermList = ({
  listKey,
  onOpenDetail,
  onUpdateMetaData,
  onDelete,
}) => {
  //SMX State Hooks
  const { loggedInUser, companyImageUrl } = useAppState();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const pageSize = PAGE_SIZE;
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [metaData, setMetaData] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleQuickView = (event, params) => {
    event.stopPropagation();
    const summaryMap = createSummaryMap(params.row);
    setSelectedRow(summaryMap);
    setDrawerOpen(true);
  };

  const handleRowClick = (params) => {
    onOpenDetail(params.row);
  };

  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
  };

  const getRowId = (row) => row.id || `${row.row_id}`;
  const columns = [
    {
      field: "quickView",
      headerName: "Quick View",
      width: 100,
      align: "center",
      flex: 0,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<ArrowCircleRightOutlined />}
          label="Quick View"
          onClick={(event) => handleQuickView(event, params)}
        />
      ),
    },
    {
      field: "payment_terms_id",
      headerName: "Payment Term ID",
      flex: 0,
      width: 300,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },

    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(params.row, event);
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const { page, pageSize } = paginationModel;
      const offset = page * pageSize;
      const data = await GetRecordList({
        objectType: CONFIG.objectType,
        pageSize: pageSize,
        offset: offset,
        searchQuery: searchQuery,
      });

      if (data.record_list && data.record_list.length > 0) {
        setRows(data.record_list);
        setTotalRows(data.total_records);
        onUpdateMetaData(data.meta_data);
      } else {
        setRows([]);
        setTotalRows(0);
        setMetaData(null);
      }
    } catch (err) {
      setError(err.message);
      setRows([]);
      setTotalRows(0);
      setMetaData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [paginationModel, searchQuery, listKey]);

  const createSummaryMap = (selectedRow) => {
    return { ...selectedRow };
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <TextField
        label={CONFIG.searchTitle}
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        sx={{ mb: 2 }}
        fullWidth
      />
      <ListBox
        key={listKey}
        rows={rows}
        columns={columns}
        totalRows={totalRows}
        loading={loading}
        paginationModel={paginationModel}
        handlePaginationModelChange={handlePaginationModelChange}
        pageSize={pageSize}
        getRowId={getRowId}
        handleRowClick={handleRowClick}
      />
      {error && <Box sx={{ color: "red", mt: 2 }}>Error: {error}</Box>}
      <DetailDrawer
        open={drawerOpen}
        onClose={handleCloseDrawer}
        selectedRow={selectedRow}
        sort={true}
        title={CONFIG.detailTitle}
      />
    </Box>
  );
};

export default PaymentTermList;
