import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SelectContent from "./SelectContent";
import MenuContent from "./MenuContent";
import OptionsMenu from "./OptionsMenu";
import { deepPurple } from "@mui/material/colors";
import { Paper } from "@mui/material";
import { useAppState } from '../../reduxModule';
import { capitalizeFirstLetter } from "../../utils/helpers";

export default function SideMenu() {
  const { loggedInUser } = useAppState();


return (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      height: "100%", // Ensure the SideMenu takes full height of its container
    }}
  >
    <Box
      sx={{
        display: "flex",
        p: 3,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "background.menu",
      }}
    >
      <Avatar
        alt={loggedInUser.name}
        src={loggedInUser.logo_url}
        sx={{
          width: 120,
          height: 120,
        }}
      />
    </Box>

    <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
      <MenuContent />
    </Box>

    <Stack
      direction="row"
      sx={{
        p: 2,
        gap: 1,
        alignItems: "center",
        borderTop: "1px solid",
        borderColor: "divider",
      }}
    >
      <Avatar sx={{ bgcolor: deepPurple[500], width: 36, height: 36 }}>
        {capitalizeFirstLetter(loggedInUser.name)}
      </Avatar>

      <Box sx={{ mr: "auto" }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, lineHeight: "16px" }}
        >
          {loggedInUser.name}
        </Typography>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {loggedInUser.company_name}
        </Typography>
      </Box>
      <OptionsMenu />
    </Stack>
  </Box>
);
}

