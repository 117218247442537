import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SelectContent from "./SelectContent";
import MenuContent from "./MenuContent";
import OptionsMenu from "./OptionsMenu";
import { deepPurple } from "@mui/material/colors";
import { Paper } from "@mui/material";
import { useAppState } from '../../reduxModule';
import { capitalizeFirstLetter } from "../../utils/helpers";
import SideMenu from "./SideMenu";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 0,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
    border:0,
  },
});

export default function SideMenuDrawer() {

  return (
    <Drawer
      variant="permanent"
      sx={{
        border: 0,
        display: { xs: "none", lg: "block" },
        [`& .${drawerClasses.paper}`]: {
          overflow: "hidden", // Hide both scrollbars
          "&:hover": {
            overflowY: "auto", // Show vertical scrollbar on hover
          },
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for WebKit browsers (Chrome, Safari, newer versions of Opera)
          },
          height: "100vh", // This makes the drawer full height
          scrollbarWidth: "none", // Hide scrollbar for Firefox
          msOverflowStyle: "none", // Hide scrollbar for Internet Explorer and Edge
        },
      }}
    >
      <SideMenu />
    </Drawer>
  );
}
