import { createSlice, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("appState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("appState", serializedState);
  } catch {
    // Ignore write errors
  }
};

// Create the slice
const appSlice = createSlice({
  name: "app",
  initialState: loadState() || {
    loggedInUser: {},
    activeUser: {},
    activeCustomer: {},
    activeOrder: {},
    activeItem: {},
    sideMenuOpen: false,
    globalSnackbarOpen: false,
    globalSnackbarMessage: "",
    companyImageUrl: "",
    breadcrumbs: [],
  },
  reducers: {
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    setActiveCustomer: (state, action) => {
      state.activeCustomer = action.payload;
    },
    setActiveItem: (state, action) => {
      state.activeItem = action.payload;
    },
    setSideMenuOpen: (state, action) => {
      state.sideMenuOpen = action.payload;
    },
    setActiveOrder: (state, action) => {
      state.activeOrder = action.payload;
    },
    showSuccessSnackbar: (state, action) => {
      state.globalSnackbarOpen = true;
      state.globalSnackbarMessage = action.payload;
    },
    clearSnackbar: (state) => {
      state.globalSnackbarOpen = false;
      state.globalSnackbarMessage = "";
    },
    setCompanyImageUrl: (state, action) => {
      state.companyImageUrl = action.payload;
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    resetStore: (state) => {
      state.loggedInUser = {};
      state.activeUser = {};
      state.activeCustomer = {};
      state.activeOrder = {};
      state.activeItem = {};
      state.sideMenuOpen = false;
      state.globalSnackbarOpen = false;
      state.globalSnackbarMessage = "";
      state.companyImageUrl = "";
      state.breadcrumbs = [];
    },
  },
});

// Create the store
const store = configureStore({
  reducer: {
    app: appSlice.reducer,
  },
});

// Subscribe to store changes
store.subscribe(() => {
  saveState(store.getState().app);
});

// Export actions
export const {
  setLoggedInUser,
  setActiveUser,
  setActiveCustomer,
  setActiveItem,
  setSideMenuOpen,
  setActiveOrder,
  showSuccessSnackbar,
  clearSnackbar,
  setCompanyImageUrl,
  setBreadcrumbs,
  resetStore,
} = appSlice.actions;

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch();

// Export a pre-typed useSelector hook for this store
export const useAppSelector = useSelector;

export const useAppState = () => {
  const dispatch = useAppDispatch();
  const {
    loggedInUser,
    activeUser,
    activeCustomer,
    activeItem,
    sideMenuOpen,
    activeOrder,
    globalSnackbarOpen,
    globalSnackbarMessage,
    companyImageUrl,
    breadcrumbs,
  } = useAppSelector((state) => state.app);

  const handleUpdateLoggedInUser = (newUser) => {
    dispatch(setLoggedInUser(newUser));
  };

  const handleUpdateUser = (newUser) => {
    dispatch(setActiveUser(newUser));
  };

  const handleUpdateCustomer = (newCustomer) => {
    dispatch(setActiveCustomer(newCustomer));
  };

  const handleUpdateItem = (newItem) => {
    dispatch(setActiveItem(newItem));
  };

  const handleUpdateSideMenuOpen = (newSideMenuOpen) => {
    dispatch(setSideMenuOpen(newSideMenuOpen));
  };

  const handleUpdateOrder = (newOrder) => {
    dispatch(setActiveOrder(newOrder));
  };

  const handleShowGlobalSnackbar = (message) => {
    dispatch(showSuccessSnackbar(message));
  };

  const handleClearSnackbar = () => {
    dispatch(clearSnackbar());
  };

  const handleUpdateCompanyImageUrl = (newCompanyImageUrl) => {
    dispatch(setCompanyImageUrl(newCompanyImageUrl));
  };

  const handleUpdateBreadcrumbs = useCallback((newBreadcrumbs) => {
    dispatch(setBreadcrumbs(newBreadcrumbs));
  }, [dispatch]);

  const handleResetStore = () => {
    dispatch(resetStore());
  };

  return {
    loggedInUser,
    activeUser,
    activeCustomer,
    activeItem,
    sideMenuOpen,
    activeOrder,
    globalSnackbarOpen,
    globalSnackbarMessage,
    companyImageUrl,
    breadcrumbs,
    handleUpdateLoggedInUser,
    handleUpdateUser,
    handleUpdateCustomer,
    handleUpdateItem,
    handleUpdateSideMenuOpen,
    handleUpdateOrder,
    handleShowGlobalSnackbar,
    handleClearSnackbar,
    handleUpdateCompanyImageUrl,
    handleUpdateBreadcrumbs,
    handleResetStore,
  };
};

export { store };
export { Provider } from "react-redux";
