import { Box, Chip, Drawer, IconButton, Typography } from "@mui/material";
import PaymentMethodDisplay from "../../Views/Customer/CustomerSpecific/PaymentMethodDisplay";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import { getEnumLabel } from "../../../utils";
import { DataGrid } from "@mui/x-data-grid";

export const ReceivedByERPCell = ({ value }) => (
  <Chip
    label={value === 0 ? "No" : "Yes"}
    color={value === 0 ? "error" : "success"}
    size="small"
  />
);


export const OrderStatusCell = ({ metaDataList, value }) => {
  const theme = useTheme();

  if (metaDataList == null || value == null) {
    return <div />;
  }

  const orderStatus = getEnumLabel("status", metaDataList, value);

  const orderStatusMap = {
    0: { backgroundColor: "success" },
    1: { backgroundColor: "primary" },
    2: { backgroundColor: "default" },
    3: { backgroundColor: "default" },
    4: { backgroundColor: "default" },
    5: { backgroundColor: "error" },
    6: { backgroundColor: "error" },
    7: { backgroundColor: "default" },
    8: { backgroundColor: "default" },
    9: { backgroundColor: "default" },
  };

  try {
    const { backgroundColor, textColor } =
      orderStatusMap[value] || orderStatusMap[6]; // Default to "Archived" if not found
    return (
      <Chip
        label={orderStatus || "Unknown"}
        color={backgroundColor}
        sx={{
          size: "small",
          color: textColor,
          variant: "contained",
          width: "140px",
          p: 1,
        }}
      />
    );
  } catch (error) {
    return <div />;
  }
};


export const PaymentStatusCell = ({ metaDataList, value }) => {
  //unpaid, paid_card, voided, partially_paid, paid_cash;

  if (metaDataList == null || value == null) {
    return <div />;
  }

  const paymentStatus = getEnumLabel("payment_status", metaDataList, value);
  if(paymentStatus === "") return <div />;

  const paymentStatusMap = {
    0: { backgroundColor: "default" },
    1: { backgroundColor: "success" },
    2: { backgroundColor: "warning" },
    3: { backgroundColor: "info" },
    4: { backgroundColor: "success" },
  };

  try {
    const { backgroundColor, textColor } =
      paymentStatusMap[value];
    return (
      <Chip
        label={paymentStatus}
        color={backgroundColor}
        sx={{
          size: "small",
          color: textColor,
          variant: "contained",
          width: "140px",
          p: 1,
        }}
      />
    );
  } catch (error) {
    return <div />;
  }
};

export const PaymentStatusGridCell = ({ value }) =>
  value === 0 ? <div /> : <Chip label="Paid" color="success" size="small" variant="outlined" />;

export const ActionTypeCell = ({ value }) => {
  let label = "";
  let color = "";

  if (value === 0) {
    label = "Insert";
    color = "success";
  } else if (value === 1) {
    label = "Update";
    color = "primary";
  } else if (value === 2) {
    label = "Delete";
    color = "danger";
  }

  return <Chip label={label} color={color} size="small" />;
};

export const RowVersionCell = ({ value }) => {
  const rowVersionMap = {
    0: { label: "SMX Web", color: "primary" },
    1: { label: "Mobile Client", color: "warning" },
    2: { label: "Market", color: "default" },
    3: { label: "External System", color: "default" },
    4: { label: "SYS", color: "error" },
    5: { label: "PortalX Web", color: "secondary" },
    6: { label: "PortalX Mobile", color: "secondary" },
    7: { label: "Unknown", color: "default" },
    8: { label: "Business Central", color: "success" },
    9: { label: "Sage", color: "default" },
    10: { label: "SAP", color: "default" },
    11: { label: "QuickBooks", color: "default" },
  };

  const { label, color } = rowVersionMap[value] || rowVersionMap[7]; // Default to "Unknown" if value is not in the map

  return <Chip label={label} color={color} size="small" />;
};

export const OrderDateCell = ({ value }) => value.substring(0, 16);

export const PaymentMethodsCell = ({ value }) => {
  return <PaymentMethodDisplay inputString={value} />;
};

export const CustomerTypeCell = ({ value }) => (
  <Chip
    label={value === 0 ? "B2B" : "B2C"}
    color={value === 0 ? "info" : "info"}
    size="small"
  />
);

export const AccountStatusCell = ({ value }) => (
  <Chip
    label={value === 0 ? "Active" : "Disabled"}
    color={value === 0 ? "success" : "error"}
    size="small"
  />
);

export const ForSaleCell = ({ value }) => (
  <Chip
    label={value === 0 ? "No" : "Yes"}
    color={value === 0 ? "error" : "success"}
    size="small"
  />
);

export const UserTypeStatusCell = ({ value }) => {
  const theme = useTheme();

  const userTypeMap = {
    0: { label: "Internal", color: "primary" },
    1: { label: "PortalX", color: "success" },
    4: { label: "ABIX", color: "secondary" },
  };

  const { label, color } = userTypeMap[value] || {
    label: "Unknown",
    color: "error",
  };

  return (
    <Chip
      label={label}
      color={color}
      size="small"
      sx={{ width: "80px", justifyContent: "center" }}
    />
  );
};

export const GetUserTypeChip = (value) => {
  const userTypeMap = {
    0: { label: "Internal User", color: "primary" },
    1: { label: "PortalX User", color: "success" },
    4: { label: "ABIX User", color: "info" },
  };

  const { label, color } = userTypeMap[value] || {
    label: "Unknown",
    color: "error",
  };

  return (
    <Chip sx={{ p: 1, py: 2 }} label={label} variant="outlined" color={color} />
  );
};

export function ListBox({
  rows,
  columns,
  totalRows,
  loading,
  paginationModel,
  handlePaginationModelChange,
  pageSize,
  getRowId,
  handleRowClick,
  rowHeight = 40,
  headerHeight = 40,
}) {
  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        sx={{ fontSize: 12 }}
        rows={rows}
        columns={columns}
        paginationMode="server"
        rowCount={totalRows}
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[pageSize]}
        getRowId={getRowId}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        disableColumnFilter={true}
        disableColumnSorting={true}
        disableColumnMenu={true}
        autoHeight={true}
      />
    </Box>
  );
}
