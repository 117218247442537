import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";

const GlobalUIContext = createContext();

export const GlobalUIProvider = ({ children }) => {
  // Spinner state
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  // Dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    content: '',
    actions: null,
  });

  // Snackbar state
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    title: "",
    message: "",
    severity: "info",
  });

  // Spinner methods
  const showSpinner = useCallback(() => {
    setSpinnerVisible(true);
  }, []);

  const hideSpinner = useCallback(() => {
    setSpinnerVisible(false);
  }, []);

  // Dialog methods
  const openDialog = useCallback((config) => {
    setDialogConfig(config);
    setDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  // Snackbar methods
  const showSnackbar = useCallback((title, message, severity = "info") => {
    setSnackbarState({ open: true, title, message, severity });
  }, []);

  const hideSnackbar = useCallback(() => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  }, []);

  const value = useMemo(
    () => ({
      // Spinner
      spinnerVisible,
      showSpinner,
      hideSpinner,
      // Dialog
      dialogOpen,
      dialogConfig,
      openDialog,
      closeDialog,
      // Snackbar
      snackbar: snackbarState,
      showSnackbar,
      hideSnackbar,
    }),
    [spinnerVisible, dialogOpen, dialogConfig, snackbarState]
  );

  return (
    <GlobalUIContext.Provider value={value}>
      {children}
    </GlobalUIContext.Provider>
  );
};

export const useGlobalUI = () => {
  const context = useContext(GlobalUIContext);
  if (!context) {
    throw new Error("useGlobalUI must be used within a GlobalUIProvider");
  }
  return context;
};
