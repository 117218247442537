import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";

import { useGlobalUI } from "../../Context/GlobalUIContext";

// Color mapping
const severityColors = {
  success: "#4caf50",
  error: "#f44336",
  info: "#2196f3",
  warning: "#ff9800",
};

// Reusable Snackbar component
const ReusableSnackbar = () => {
  const { snackbar, hideSnackbar } = useGlobalUI();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    hideSnackbar();
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={snackbar.open}
      autoHideDuration={3000}
      onClose={handleClose}
      action={action}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar.severity}
        variant="filled"
        icon={<InfoRounded sx={{ color: "#ffffff", display: "flex", alignItems: "center", height: "100%", }} />}
        sx={{
          width: "100%",
          backgroundColor:
            severityColors[snackbar.severity] || severityColors.info,
          color: "#ffffff",
        }}
      >
        <>
          <strong>{snackbar.title}</strong>
          <br />
          {snackbar.message}
        </>
      </Alert>
    </Snackbar>
  );
};

export default ReusableSnackbar;
