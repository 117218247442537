import * as React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import getDashboardTheme from "../../theme/getDashboardTheme";
import { useAppState } from '../../reduxModule';



function TemplateFrame({
  mode,
  toggleColorMode,
  children,
}) {
  const { loggedInUser } = useAppState();
  const dashboardTheme = createTheme(getDashboardTheme(mode));

  return (
    <ThemeProvider theme={dashboardTheme}>
      <Box
        sx={(theme) => ({
          height: "100dvh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.background.backdrop
        })}
      >
       
        <Box sx={{ flex: "1 1", overflow: "auto" }}>{children}</Box>
      </Box>
    </ThemeProvider>
  );
}

TemplateFrame.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default TemplateFrame;
