import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export default function MyTasksCard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  return (
    <Card variant="outlined" sx={{ height: "100%", flexGrow: 1, mb: 2 }}>
      <CardContent>
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: "600" }}
        >
          Check out your tasks
        </Typography>
        <Typography sx={{ color: "text.secondary", mb: "8px" }}>
          Uncover performance and visitor insights with our data wizardry.
        </Typography>
        <Button
          variant="contained"
          size="small"
          endIcon={<ChevronRightRoundedIcon />}
          fullWidth={isSmallScreen}
          color='primary'
          onClick={() => navigate("/my-tasks")}
        >
         My Tasks
        </Button>
      </CardContent>
    </Card>
  );
}
