import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Avatar,
  Link,
  Alert,
} from "@mui/material";
import LockOpen from "@mui/icons-material/LockOpen";
import { useAppState } from "../../reduxModule";
import { GetSingleRecordUsingId, ResetPassword, ValidateResetToken } from "../../utils";
import { useGlobalUI } from "../../Context/GlobalUIContext";

const ResetPasswordView = () => {
  const params = useParams();
  const resetToken = params.token;

  
  //SMX State Hooks
  const { loggedInUser, companyImageUrl, handleUpdateBreadcrumbs } = useAppState();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showSpinner, hideSpinner, showSnackbar, openDialog, closeDialog, dialogConfig } = useGlobalUI();
  


  const [loginId, setLoginId] = useState("salesmachinex@acvenco.com");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetComplete, setResetComplete] = useState(false);
  const [returnData, setReturnData] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(null);


  const handleResetPassword = async (event) => {



    event.preventDefault();


    if(password != confirmPassword){
      setError("Passwords do not match");
      return;
    }

     setError(null);

     showSpinner();
     try {
       const data = await ResetPassword({
         resetToken: resetToken,
         password: password,
       });
       if(data.error_code == 0){
        setResetComplete(true);
        setReturnData(data);
        console.log(data);
       }else{
        setError(data.error_message);
       }

      
     } catch (error) {
       setResetComplete(false);
       setError(error.message);
     } finally {
       hideSpinner();
     }
  };

  const fetchData = async () => {
    showSpinner();
    try {
      const data = await ValidateResetToken({
        resetToken: resetToken,
      });
      console.log(data);

      if (data.token_is_valid === 0) {
        setIsTokenValid(false);
        return;
      }

      if (data.reset_token == resetToken) {
        setIsTokenValid(true);
      } else {
        setIsTokenValid(false);
      }

    } catch (error) {
      setError(error.message);
    } finally {
      hideSpinner();
    }
  };

  useEffect(() => {
    fetchData();
  }, [resetToken]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h3">
          S a l e s M a c h i n e X
        </Typography>
        <Avatar sx={{ m: 2, bgcolor: "primary.main" }}>
          <LockOpen />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
          Reset Your Password
        </Typography>
        {isTokenValid != null ? (
          isTokenValid ? (
            <Box sx={{ mt: 1, width: "100%" }}>
              {resetComplete ? (
                <>
                  <Alert severity="success" sx={{ mt: 5, textAlign: "center" }}>
                    Password reset successfully.
                  </Alert>
                  {returnData != null && (
                    <Box sx={{ mt: 5, textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        href={`https://${returnData.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Go to Login
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    onClick={handleResetPassword}
                  >
                    Reset Password
                  </Button>
                  {error && (
                    <Alert severity="error" sx={{ mt: 5 }}>
                      {error}
                    </Alert>
                  )}
                </>
              )}
            </Box>
          ) : (
            <Alert severity="error" sx={{ mt: 5 }}>
              The Reset Token is Invalid. Please try resetting your password
              again.
            </Alert>
          )
        ) : null}
      </Box>
    </Container>
  );
};

export default ResetPasswordView;
