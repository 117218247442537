import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";
import DOMPurify from "dompurify";

export default function TextCard({ title, content }) {
  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <Card variant="outlined" sx={{ height: "100%", flexGrow: 1, mb:2 }}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
            dangerouslySetInnerHTML={createMarkup(content)}
          />
        </CardContent>
      </CardActionArea>

    </Card>
  );
}
