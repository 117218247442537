// src/utils/helpers.js


export function formatDate(date) {
  return new Date(date).toLocaleDateString();
}

export function calculateAge(birthDate) {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age;
}

export const createSubsetObject = (sourceObject, fieldsToInclude) => {
  return fieldsToInclude.reduce((acc, key) => {
    if (key in sourceObject) {
      let value = sourceObject[key];
      if (key === "erp_document_number" && value === null) {
        value = "";
      } else if (key === "received_by_ERP_date" && value === null) {
        value = "N/A";
      } else if (key === "owner_id" && (value === null || value === "null")) {
        value = "N/A";
      }

      acc[key] = value;
    }
    return acc;
  }, {});
};

export const capitalizeFirstLetter = (string) => {
  if (string && typeof string === "string") {
    return string[0].toUpperCase();
  } else {
    return "";
  }
};

export function getEnumLabel(metaValue, metaDataList, value) {
  if(metaDataList == null) return "";
  const metaValues = metaDataList.filter(
    (item) => item.property_name === metaValue
  )[0];

  const enumLabels = metaValues.enum_label.split(","); 
  return enumLabels[value] || "";
}


