import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Avatar,
  Link,
  Alert,
} from "@mui/material";
import LockOpen from "@mui/icons-material/LockOpen";
import {
  GeneratePasswordReset,
  GetCompanyId,
} from "../../utils";
import { useGlobalUI } from "../../Context/GlobalUIContext";

const ForgotPasswordView = () => {
  //SMX State Hooks
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const {
    showSpinner,
    hideSpinner,
    showSnackbar,
    openDialog,
    closeDialog,
    dialogConfig,
  } = useGlobalUI();

  const [loginId, setLoginId] = useState("");
  const [resetComplete, setResetComplete] = useState(false);
  const [returnData, setReturnData] = useState(null);
  const [isLoginIdValid, setIsLoginIdValid] = useState(null);

  const handleGeneratePasswordReset = async (companyId) => {
    const data = await GeneratePasswordReset({
      loginId: loginId,
      companyId: companyId,
    });
    if (data.code === 0) {
      setResetComplete(true);
    }
    hideSpinner();
  };

  const showCompanyList = () => {
    console.log("showCompanyList");
  };

  const handleGetCompanyId = async (event) => {
    setIsLoginIdValid(null);
    setError(null);
    event.preventDefault();

    if (!loginId) {
      setError("Please enter a Login ID");
      return;
    }

    showSpinner();
    try {
      const data = await GetCompanyId({
        loginId: loginId,
      });
      console.log(data);

      if (data.length === 0) {
        hideSpinner();
        setIsLoginIdValid(false);
        return;
      }
      if (data.length === 1) {
        const companyId = data[0].company_id;
        handleGeneratePasswordReset(companyId);
        return;
      }
      if (data.length > 1) {
        hideSpinner();
        showCompanyList();
        return;
      }
    } catch (error) {
      setError(error.message);
    } finally {
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h3">
          S a l e s M a c h i n e X
        </Typography>
        <Avatar sx={{ m: 2, bgcolor: "primary.main" }}>
          <LockOpen />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
          Forgot Your Password?
        </Typography>
        <p style={{ my: 3, textAlign: "center" }}>
          Forgot your password? Don't worry! Just enter your Login ID below, and
          we'll send you a link to reset it. It's quick, simple, and secure.
        </p>
         <Box
          component="form"

          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          {resetComplete ? (
            <Alert severity="success" sx={{ mb: 2 }}>
              Success! Please check your email for the reset link.
            </Alert>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                name="login_id"
                label="Login ID"
                type="text"
                id="login_id"
                value={loginId}
                onChange={(e) => setLoginId(e.target.value.trim())}
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={handleGetCompanyId}
              >
                Reset Password
              </Button>
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Link href="/login" variant="body2">
              Back to Login
            </Link>

          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 5 }}>
              {error}
            </Alert>
          )}
          {isLoginIdValid === false && (
            <Alert severity="error" sx={{ mt: 5 }}>
              That Login Id was not found. Please try again.
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPasswordView;
