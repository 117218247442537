import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AccountTreeOutlined from "@mui/icons-material/AccountTreeOutlined";
import HistoryOutlined from "@mui/icons-material/HistoryOutlined";
import Person2Outlined from "@mui/icons-material/Person2Outlined";
import Person3Outlined from "@mui/icons-material/Person3Outlined";
import ShoppingBagOutlined from "@mui/icons-material/ShoppingBagOutlined";
import ReceiptOutlined from "@mui/icons-material/ReceiptOutlined";
import AttachMoneyOutlined from "@mui/icons-material/AttachMoneyOutlined";
import FileOpen from "@mui/icons-material/FileOpen";
import FilePresent from "@mui/icons-material/FilePresent";
import ShoppingBasket from "@mui/icons-material/ShoppingBasket";
import SwapHorizOutlined from "@mui/icons-material/SwapHorizOutlined";
import VerifiedUserSharp from "@mui/icons-material/VerifiedUserSharp";

import QuoteInfoCard from "../Dashboard/StatCards/QuoteInfoCard";
import { Box } from "@mui/material";

const mainListItems = [
  { text: "Dashboard", icon: <HomeRoundedIcon />, link: "/dashboard" },
  { text: "Customers", icon: <Person3Outlined />, link: "/customers" },
  { text: "Contacts", icon: <VerifiedUserSharp />, link: "/contacts" },
  {
    text: "Sales",
    icon: <AttachMoneyOutlined />,
    subItems: [
      { text: "Orders", icon: <FileOpen />, link: "/orders" },
      { text: "Quotes", icon: <FilePresent />, link: "/quotes" },
      { text: "Invoices", icon: <ReceiptOutlined />, link: "/invoices" },
      { text: "Returns", icon: <SwapHorizOutlined />, link: "/returns" },
      { text: "Baskets", icon: <ShoppingBasket />, link: "/baskets" },
    ],
  },
  {
    text: "Sales History",
    icon: <HistoryOutlined />,
    subItems: [
      {
        text: "Order History",
        icon: <FileOpen />,
        link: "/orderhistory",
      },
      {
        text: "Quote History",
        icon: <FilePresent />,
        link: "/quotehistory",
      },
      {
        text: "Invoice History",
        icon: <ReceiptOutlined />,
        link: "/invoicehistory",
      },
      {
        text: "Return History",
        icon: <SwapHorizOutlined />,
        link: "/returnhistory",
      },
      {
        text: "Basket History",
        icon: <ShoppingBasket />,
        link: "/baskethistory",
      },
    ],
  },
  { text: "Items", icon: <ShoppingBagOutlined />, link: "/items" },
  { text: "Collections", icon: <AccountTreeOutlined />, link: "/collections" },
  { text: "Users", icon: <Person2Outlined />, link: "/users" },
];

const secondaryListItems = [
  { text: "Settings", icon: <SettingsRoundedIcon />, link: "/settings" },
  { text: "About", icon: <InfoRoundedIcon />, link: "/about" },
  { text: "Feedback", icon: <HelpRoundedIcon />, link: "/feedback" },
];

export default function MenuContent() {
  const location = useLocation();
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleSubMenuClick = (text) => {
    setOpenSubMenu(openSubMenu === text ? null : text);
  };

  const renderListItems = (items) =>
    items.map((item, index) => (
      <React.Fragment key={index}>
        <ListItem
        
          disablePadding
          sx={{
            height: 40,
            display: "block",
            mb: 0,
            backgroundColor:
              location.pathname === item.link ? "primary.main" : "transparent",
          }}
        >
          <ListItemButton
            sx={{ height: 40 }}
            component={item.subItems ? "div" : Link}
            to={item.link}
            selected={location.pathname === item.link}
            onClick={() => item.subItems && handleSubMenuClick(item.text)}
          >
            <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{ fontSize: 10, color: "white" }} // Correct way to set font size
            />
            {item.subItems &&
              (openSubMenu === item.text ? (
                <ExpandLess sx={{ color: "white" }} />
              ) : (
                <ExpandMore sx={{ color: "white" }} />
              ))}
          </ListItemButton>
        </ListItem>
        {item.subItems && (
          <Collapse in={openSubMenu === item.text} timeout="auto" unmountOnExit>
            <List
              key={item.text}
              component="div"
              disablePadding
              sx={{ mb: 1, mt: 0, backgroundColor: "background" }}
            >
              {item.subItems.map((subItem, subIndex) => (
                <ListItem
                  key={subIndex}
                  disablePadding
                  sx={{
                    height: 40,
                    display: "block",
                    backgroundColor:
                      location.pathname === subItem.link
                        ? "primary.main"
                        : "transparent",
                  }}
                >
                  <ListItemButton
                    key={subIndex}
                    component={Link}
                    to={subItem.link}
                    selected={location.pathname === subItem.link}
                    sx={{ pl: 4, height: 40 }}
                  >
                    <ListItemIcon sx={{ color: "white" }}>
                      {subItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={subItem.text}
                      primaryTypographyProps={{ fontSize: 12, color: "white" }} // Correct way to set font size
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));

  return (
    <Stack
      sx={{
        height: "100%", // Ensure the Stack takes full height
        backgroundColor: "background.menu",
      }}
    >
      <List dense key="mainListItems">{renderListItems(mainListItems)}</List>
      <Box flexGrow={1} />{" "}
      {/* This empty Box will expand to fill available space */}
      <List dense>{renderListItems(secondaryListItems)}</List>
    </Stack>
  );
}
