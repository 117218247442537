import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { AppRoutes } from "./Routes";
import { Provider, store } from "./reduxModule";
import { GlobalUIProvider } from "./Context/GlobalUIContext";
import ReusableDialog from "./components/Utils/ReusableDialog";
import ReusableSnackbar from "./components/Utils/ReusableSnackbar";
import Spinner from "./components/Utils/ReusableSpinner";

const App = () => {
  return (
    <Provider store={store}>
      <GlobalUIProvider>
        <Router>
          <div className="App">
            <AppRoutes />
            <ReusableSnackbar />
            <ReusableDialog />
            <Spinner />
          </div>
        </Router>
      </GlobalUIProvider>
    </Provider>
  );
};

export default App;
